/* --------------------------- DOMAIN ------------------------------------------- */
.dd-manage-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  transition: 0.3s linear;
  position: relative;
  &.detail {
    transition: 0.3s;
    grid-template-columns: 60% 40%;
  }
  &.list {
    transition: 0.3s;
    grid-template-columns: 1fr 0fr;
  }
}

.dd-manage-input-form {
  display: flex;
  align-items: center;
  margin: 10px 0px;
}
.dd-manage-label {
  width: 33%;
  margin: 5px;
  display: flex;
  align-items: center;
  font-weight: 700;
}
.dd-manage-input {
  width: 66%;
  position: relative;
  &:disabled {
    background-color: rgb(202, 202, 202);
  }
}
.dd-manage-radio {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  .radio-label {
    font-size: 15px;
    font-weight: bold;
    margin-right: 10px;
  }
}
.dd-manage-possible-select {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}
.dd-manage-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dd-manage-select {
  width: 100%;
  &:disabled {
    background-color: rgb(219, 219, 219);
  }
}
.dd-manage-textarea {
  width: 100%;
  height: fit-content;
}
.dd-manage-history {
  position: absolute;
  width: 50%;
  background-color: var(--backgound-color);
  left: 100%;
  z-index: 1;
  transition: 0.5s;
  &.show {
    left: 0%;
    width: 60%;
    transition: 0.5s;
  }
}

.dd-manage-contents {
  position: absolute;
  left: 100%;
  width: 50%;
  background-color: var(--backgound-color);
  transition: 0.3s;
  z-index: 2;
  &.show {
    width: 40%;
    left: 60%;
    transition: 0.3s;
  }
}

.multiLang-bottom {
  padding-bottom: 5px;
  border-bottom: 1px solid lightgray;
  border-bottom-style: double;
}
.multiLang-right {
  border-right: 1px solid lightgray;
}
