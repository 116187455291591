/************************************************ 좌측 Navigation var Style 설정 ************************************************/

.left-nav {
  height: 100%;
  display: grid;
  grid-row: span 2;
  grid-template-rows: 55px 1fr;
}

/************************************************ Logo ************************************************/
.main-logo {
  border-right: 1px solid lightgray;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 55px;
}

.main-logo .title-wrapper {
  display: flex;
  flex-direction: column;
}
.main-logo .title-wrapper.mini {
  display: none;
}
.main-logo .title-wrapper .title {
  font-size: 15px;
  font-weight: 700;
  font-style: italic;
}
.main-logo .title-wrapper .title.semi {
  font-size: 10px;
  font-weight: 100;
  color: gray;
}

/*********************************************** Navigation bar ***********************************************/
.nav-area {
  padding: 20px 15px;
  background-color: #032d7c;
  justify-content: space-between;
  flex-direction: column;
  overflow-y: auto;
  display: flex;
  position: relative;
}

.nav-area.mini {
  padding: 20px 0;
  z-index: 10;
}

.nav-area .icon {
  color: #ffffff;
  font-size: 14px;
}

.nav-area .selected {
  background-color: #022567;
  color: white;
}

.nav-area .contents {
  border-radius: 8px;
  width: 100%;
  color: lightgray;
  margin: 5px 0;
}

.nav-area .contents.show {
  background-color: #022567;
}
.nav-area .contents.show .contents-header {
  background-color: #012260;
}

.nav-area .contents .contents-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  gap: 5px;
  height: 50px;
  border-radius: 8px;
  padding: 0px 5px;
  cursor: pointer;
}

.nav-area .contents .contents-header:hover {
  background-color: #3e5dd1;
  color: white;
}
.nav-area .contents .contents-header:hover .icon {
  color: #6386c6;
}

.nav-area .contents .contents-menu {
  padding: 3px 0;
}

.nav-area .contents .contents-menu.hidden {
  visibility: hidden;
  height: 0;
}
.nav-area .contents .contents-menu.show {
  visibility: show;
  transition: 0.2s linear;
}

.nav-area .contents .contents-menu ul {
  margin-top: 5px;
  padding-left: 15px;
}

.nav-area .contents .contents-menu ul li {
  cursor: pointer;
  margin-bottom: 3px;
  color: #6d8ed0;
  font-weight: bold;
  line-height: 30px;
}

.nav-area .contents .contents-menu ul li::marker {
  color: #264b93;
  font-size: 7px;
  font-weight: bold;
}
.nav-area .contents .contents-menu ul li:hover {
  color: white;
}

.nav-area .contents .contents-menu ul li:hover::marker {
  color: #0099fb;
}
/****************************side menu mini ************************************************/
.contents-header.mini {
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  user-select: none;
  padding: 5px;
}

.contents-header.mini.selected {
  background-color: transparent;
  position: relative;
}

.contents-header.mini.selected::before {
  content: "";
  height: 100%;
  width: 3px;
  background-color: #0099fb;
  position: absolute;
  top: 0;
  left: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.contents-header.mini .contents-name {
  font-size: 11px;
  color: #ffffff;
  word-break: keep-all;
  text-align: center;
  margin-top: 1px;
}

.contents-header.mini.selected > * {
  color: #eaf2fc;
}

.contents-header.mini .icon {
  font-size: 25px;
  transition: 0.3s;
}

.contents-header.mini:hover > * {
  color: #eaf2fc;
}
.contents-header.mini:hover .icon {
  font-size: 28px;
}

/*********************************** 사이드바 Mini 타입으로 할 때 나오는  Context 메뉴************************************************/
.mini-context-menu {
  position: fixed;
  width: 280px;
  height: calc(100% - 55px);
  background: #ffffff;
  top: 55px;
  left: -280px;
  z-index: 0;
  transition: 0.2s;
  padding: 12px 6px;
}
.mini-context-menu.hidden {
  left: -280px;
}
.mini-context-menu.show {
  left: 75px;
  z-index: 15;
  box-shadow: 1px 1px 5px gray;
}

.mini-context-menu .context-menu-header {
  height: 40px;
  display: flex;
  padding: 0 20px;
  align-items: center;
  gap: 15px;
}
.mini-context-menu .context-menu-header .icon {
  font-size: 15px;
}
.mini-context-menu .context-menu-header .text {
  font-size: 15px;
  font-weight: bold;
}

.mini-context-menu ul li {
  line-height: 30px;
  cursor: pointer;
  margin: 4px 0;
  transition: 0.3s;

  &::before {
    content: "▷";
    margin-right: 5px;
  }
  &:hover {
    &::before {
      content: "▶";
    }
    cursor: pointer;
    font-size: larger;
    font-weight: bold;

    color: var(--main-color);
  }
}
