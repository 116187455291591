table {
  width: 100%;

  thead {
    font-weight: 600;
    height: 35px;
    background-color: #f0f0f0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;

    top: 0;
  }

  tbody {
    overflow-y: auto;
    box-shadow: 1px 1px 3px gray;
    & td {
      cursor: pointer;
      vertical-align: middle;
    }
  }

  th {
    text-align: start;

    &.numbering {
      width: 30px;
      border-right: 1px solid lightgray;
      text-align: center;
      vertical-align: middle;
    }
    &.row-draggable {
      cursor: grab;
    }
  }

  tr {
    padding: 4px;

    &.selected > th,
    &.selected > td {
      color: var(--main-color);
      background-color: var(--light-color);
    }
  }

  .search-bar {
    background-color: white;
  }
  .search-bar-divider {
    height: 10px;
    border: none;
    background-color: white;
  }
}

.dd-manage-label-cell {
  display: flex;
  height: 100%;
  justify-content: start;
  align-items: center;
}
