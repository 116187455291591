:root {
  --tree-icon-color: #6a767c;
}

.table-tree {
  width: 10%;
  min-width: 200px;
  height: 100%;
  background-color: white;
  padding: 1px;
  box-shadow: 1px 0px 5px lightgray;
  position: relative;
  transition: 0.3s;

  &.hidden {
    width: 0;
    min-width: 0;

    & .expand-button {
      right: -8px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    & > .tree-item,
    & > .tree-item-child {
      display: none;
    }
  }

  & .expand-button {
    border: 1px solid var(--main-color);
    border-radius: 20px;
    cursor: pointer;
    background-color: white;
    width: 10px;
    height: 60px;
    position: absolute;
    right: -5px;
    top: calc(50% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: var(--main-color);
      color: var(--light-color);
    }
  }
}
.tree-item {
  color: var(--tree-icon-color);
  border-radius: 2px;
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  gap: 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;

  transition: 0.5s;

  &:hover {
    background-color: #f4f5f6;
  }

  &.selected {
    background-color: var(--light-color);
    color: var(--main-color);
    position: relative;

    &::before {
      content: "";
      width: 3px;
      background-color: var(--main-color);
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
}
.tree-item-child {
  transition: 0.2s;
}
