@import "../main.scss";

.add-user-wrapper {
  .user-list {
    margin-top: 3px;
    height: 300px;
    border: 1px solid var(--light-color);
    border-radius: 5px;
  }
}
