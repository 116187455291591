.command-line {
  width: 100%;
  height: 40px;
  background-color: rgb(246, 246, 246);
  border-bottom: 1px solid lightgray;
  padding: 2px 1px;
  display: flex;
  position: relative;

  & .command-button {
    border: none;
    background-color: transparent;
    border-radius: 6px;
    text-align: center;
    vertical-align: middle;
    height: 100%;
    width: 40px;
    font-size: 20px;

    &:hover:not(:disabled) {
      background-color: var(--light-color);
      color: var(--main-color);
    }
    &:active {
      color: var(--light-color);
      background-color: var(--main-color);
    }
    &:disabled {
      cursor: not-allowed;
      color: gray;
    }
  }
  & .command-button-group {
    margin-left: 10px;
  }

  & .right-side {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    gap: 3px;

    & > * {
      display: flex;
      align-items: center;
    }
  }
}
