.table-drop-cursor {
  cursor: url("../../../images/trd_mouse.png") auto !important;
}

.trd-editor-wrapper {
  width: calc(100vw - 75px);
  height: calc(100vh - 55px);
  position: relative;
  & .table-drop-cursor {
    & .react-flow__pane {
      cursor: cell;
    }
  }

  & .command-line {
    position: absolute;
    z-index: 5;

    & .trd-title {
      height: 100%;
      display: flex;
      align-items: center;
      font-weight: bold;
      font-style: italic;
      margin-right: 30px;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        font-size: larger;
      }
    }
    & .trd-breadcumb {
      font-weight: bold;
    }

    & .area-tab {
      padding: 0 15px;
      font-weight: bold;
      border-right: 1px solid lightgray;
      height: 100%;
      display: flex;
      align-items: center;

      cursor: pointer;
      &:first-child {
        border-left: 1px solid lightgray;
      }
      &.selected {
        background-color: var(--main-color);
        color: var(--light-color);
      }
      &:hover:not(.selected) {
        background-color: var(--light-color);
        color: var(--main-color);
      }
    }
  }

  & .table-tree {
    position: absolute;
    z-index: 5;
    top: 30px;
  }

  &.dark {
    background-color: #3c3c3c;
    & .erd-table-wrapper {
      border: 4px solid #202020;
    }
  }

  & .builder-bar {
    position: absolute;
    top: 40px;
    left: 0;
    height: calc(100% - 40px);
    width: 40px;
    z-index: 10;
    background-color: rgb(246, 246, 246);
    border-right: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    gap: 5px;

    & .editor-btn {
      border: none;
      background-color: transparent;
      border-radius: 1px;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 0;
      transition: 0.1s;
      cursor: pointer;
      &.selected {
        color: var(--main-color);
        background-color: var(--light-color);
      }

      &:hover {
        font-size: 22px;
        color: var(--main-color);
      }
    }
  }

  & .list-body {
    width: calc(100% - 40px);
    position: absolute;
    right: 0px;
    top: 40px;
    height: 100%;
    margin: 15px 0;
    overflow: auto;
  }
}

.erd-table-wrapper {
  width: 500px;
  background-color: #3c3c3c;
  border: 4px solid white;
  padding: 10px;
  position: relative;
  box-shadow: 1px 1px 5px gray;

  &.selected {
    outline: 3px solid dodgerblue;
    &.dark {
      outline: 3px solid white;
    }
  }

  &.imported {
    background-color: #6a767c;
    border-color: #3c3c3c;
  }

  & .mini-zoom-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #3c3c3ca3;
    color: whitesmoke;
    display: flex;
    justify-content: center;
    & .table-name {
      position: absolute;
      top: -1%;
      font-size: 45px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      text-shadow: 1px 1px 5px #3c3c3c;
    }
  }

  & .title {
    display: flex;
    justify-content: space-between;
    color: whitesmoke;
    padding: 3px 5px;
    font-size: 16px;
  }

  & .header {
    display: grid;
    height: 35px;
    grid-template-columns: 1fr 4fr 4fr 3fr;

    & .cell {
      color: whitesmoke;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #202020;
      border-right: 1px solid lightgray;
      &:first-child {
        border-top-left-radius: 5px;
      }
      &:last-child {
        border-right: none;
      }
    }
  }

  & .body {
    width: 100%;

    & .body-row {
      display: grid;
      grid-template-columns: 1fr 4fr 4fr 3fr;

      & .cell {
        border-right: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        padding: 3px 0;
        padding-left: 3px;
        color: whitesmoke;

        &:last-child {
          border-right: none;
        }
      }
    }
  }
}

.erd-table-column-grid {
  & .cell {
    &.center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & .header {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: black;
    padding: 3px 3px;

    & .cell {
      color: whitesmoke;
      padding: 2px 0;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }

  & .body {
    max-height: 500px;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px 3px;
    overflow: auto;
    overflow-x: hidden;
  }
}

.workflow-node-list.light {
  position: absolute;
  top: 100px;
  left: 3px;
  width: 200px;
  height: 250px;
  background: white;
  outline: 1px solid gray;
  z-index: 4;
  border-radius: 5px;
  box-shadow: 1px 1px 3px black;
  overflow-y: auto;
}
.workflow-node-list.dark {
  position: absolute;
  top: 100px;
  left: 3px;
  width: 200px;
  height: 250px;
  outline: 1px solid gray;
  z-index: 5;
  border-radius: 5px;
  box-shadow: 1px 1px 3px gray;
  overflow-y: auto;
  background: #3c3c3c;
}
.workflow-node-list .node.light {
  height: 50px;
  font-weight: 500;
  display: flex;
  color: #3c3c3c;
  border-bottom: 1px solid #3c3c3c;
  padding-left: 10px;
  gap: 10px;
  cursor: pointer;
  user-select: none;
}
.workflow-node-list .node.dark {
  height: 50px;
  font-weight: 500;
  display: flex;
  border-bottom: 1px solid gray;
  padding-left: 10px;
  gap: 10px;
  cursor: pointer;
  user-select: none;
  color: white;
}

.workflow-node-list .node .icon {
  display: flex;
  align-items: center;
  height: 100%;
}
.workflow-node-list .node .label {
  display: flex;
  align-items: center;
  height: 100%;
}

.flow-entity-list {
  position: absolute;
  width: 200px;
  height: 200px;
  background: lightgray;
  z-index: 6;
  margin: 15px;
  border-radius: 5px;
  border: 1px solid gray;
  box-shadow: 1px 1px 3px black;
  color: #3c3c3c;
  transition: 0.3s;
  top: 40px;
  left: -12px;
}
.flow-entity-list.flow-collapse {
  height: 30.5px;
  transition: 0.3s;
}

.flow-entity-list .header {
  font-weight: bold;
  padding: 5px;
  border-bottom: 1px solid #3c3c3c;
  height: 30.5px;
  display: flex;
  justify-content: space-between;
}

.flow-entity-list.flow-collapse .header {
  border-bottom: none;
}

.flow-entity-list .header span {
  cursor: pointer;
  user-select: none;
}
.flow-entity-list .body-list {
  max-height: 170px;
  overflow-y: auto;
  padding-bottom: 10px;
}
.flow-entity-list .body-list .node {
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 3px;
  cursor: pointer;
}
.flow-entity-list .body-list .node:hover {
  color: white;
  background-color: #3c3c3c;
  border-radius: 3px;
}

.erd-table-wrapper .handle-wrapper {
  visibility: hidden;
}
.erd-table-wrapper:hover .handle-wrapper {
  visibility: visible;
}

/* 관계 설정 팝업 스타일 */
.erd-table-relation-wrapper .button-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.erd-table-relation-wrapper .button-wrapper .identifying {
  height: 150px;
  width: 50%;
  font-size: 16px;
}

/* 관계 설정 노드 */
.erd-connector {
  width: 83px;
  height: 51px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  border-radius: 5px;
  box-shadow: 1px 2px 3px black;
  font-weight: bold;
  padding-right: 50px;
  padding-left: 20px;
  padding: 0;
  background-color: lightgray;
}
.erd-connector div {
  white-space: pre-wrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 100%;
}

.erd-connector button {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 15px;
}
.erd-connector .handle-wrapper {
  visibility: hidden;
}

.erd-toolbar .side {
  padding: 3px;
  display: flex;
  background-color: dodgerblue;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: white;
}
.dark .erd-toolbar .side {
  background-color: white;
  color: #3c3c3c;
}
.erd-toolbar .side.left {
  font-weight: 700;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.erd-toolbar .side.right {
}

.erd-toolbar .side.right span {
  cursor: pointer;
  padding: 0 4px;
}
.erd-toolbar {
  height: auto;
  position: absolute;
  width: 100%;
  bottom: calc(100%);
  display: flex;
  justify-content: space-between;
}

.erd-toolbar .entity-memo {
  width: 100%;
  position: absolute;
  height: 200px;
  top: calc((200px + 100%) * -1);
  background-color: beige;
  box-shadow: 1px 2px 3px black;
  border-radius: 5px;
  transition: 0.3s;
  color: #3c3c3c;
}

.erd-toolbar .entity-memo .header {
  height: 30px;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: flex-end;
}
.erd-toolbar .entity-memo .header button {
  border: none;
  background-color: beige;
}

.erd-toolbar .entity-memo.hidden {
  width: 0;
  height: 0;
  visibility: hidden;
  transition: 0.3s;
}
.erd-toolbar .entity-memo.hidden * {
  display: none;
}
.erd-toolbar .entity-memo .description-wrapper {
  height: calc(100% - 30px - 40px);
}

.erd-toolbar .entity-memo .description-wrapper .description {
  min-width: 190px;
  min-height: 90px;
  height: 100%;
  white-space: pre-wrap;
  overflow-y: auto;
  word-break: break-word;
}

.erd-toolbar .entity-memo .description-wrapper textarea.description {
  border-width: 2px;
  width: 100%;
  height: 100%;
  overflow: auto;
  resize: none;
}
.erd-toolbar .entity-memo .button-wrapper {
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  justify-content: space-between;
}
.erd-toolbar .entity-memo .button-wrapper .memo-info {
  display: flex;
  gap: 5px;
}
.relation-list-popup .relation-add-button-col {
  display: flex;
  justify-content: flex-end;
}

.relation-list-popup .relation-list-wrapper {
  height: 200px;
  border-radius: 5px;
  overflow-y: auto;
}
.relation-list-popup .relation-list-wrapper .header {
  background-color: #3c3c3c;
  color: white;
  height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.relation-list-popup .relation-list-wrapper .header .col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.relation-list-popup .relation-list-wrapper .body {
  border: 1px solid lightgray;
  border-top: none;
  height: calc(100% - 40px);
}

.relation-list-popup .relation-list-wrapper .body .empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.relation-list-popup .relation-list-wrapper .body .row {
  border-bottom: 1px solid lightgray;
  padding: 3px 0;
}

.relation-list-popup .relation-list-wrapper .body .row .col {
  display: flex;
  align-items: center;
}

.relation-list-popup .relation-list-wrapper .body .row .col.cell-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.erd-area-template {
  border: 3px solid gray;
  border-radius: 5px;
}
.erd-area-template .header {
  position: absolute;
  top: -30px;
  height: 30px;
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: gray;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
  /* outline: 3px solid gray; */
}

.reactflow-wrapper.erd .edit-source .edit-source-options {
  display: flex;
  justify-content: flex-end;
}

.targetHandle {
  width: 15px;
  height: 15px;
  background-color: #3c3c3c;
  border: 2px solid white;
}

.targetHandle.react-flow__handle-top {
  top: -5px;
}
.targetHandle.react-flow__handle-bottom {
  bottom: -5px;
}
.targetHandle.react-flow__handle-left {
  left: -5px;
}
.targetHandle.react-flow__handle-right {
  right: -5px;
}

.trd-memo {
  height: 100%;
  background-color: beige;
  box-shadow: 1px 2px 3px black;
  border-radius: 5px;
  & .header {
    height: 30px;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;

    & .title {
      width: calc(100% - 45px);
    }

    & .button-area {
      width: 45px;
      display: flex;

      & button {
        border: none;
        background-color: beige;
      }
    }
  }
  & .description-wrapper {
    height: calc(100% - 30px);
    overflow-y: auto;
    padding: 5px;

    & .description {
      min-width: 190px;
      min-height: 90px;
      height: 100%;
      white-space: pre-wrap;
    }

    & textarea.description {
      border-width: 2px;
      width: 100%;
      height: calc(98% - 40px);
      overflow: auto;
      resize: none;
    }

    & .button-area {
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 10px;
      gap: 5px;
    }
  }
}

.trd-history-wrapper {
  height: 100%;
  overflow: auto;

  & tbody {
    & .change-arrow {
      text-align: center;
      color: limegreen;
    }
  }

  & .main-table {
    & thead {
      & .contents-header {
        width: 40%;
      }
      & .key-header {
        width: 15%;
      }
    }
  }

  & .change-table {
    & th.contents-header {
      width: 40%;
    }
  }
}
/*
.changed-table {
  & .accordion-header {
    border-color: #198754;
  }

  & .accordion-item:last-of-type .accordion-button.collapsed {
    color: #198754;
  }

  
  & .accordion-button:not(.collapsed) {
    color: white;
    background-color: #198754;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  }
}

.added-table {
  & .accordion-header {
    border-color: #0096cc;
  }

  & .accordion-item:last-of-type .accordion-button.collapsed {
    color: #0096cc;
  }

  
  & .accordion-button:not(.collapsed) {
    color: white;
    background-color: #0096cc;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  }
}

.deleted-table {
  & .accordion-header {
    border-color: tomato;
  }

  & .accordion-item:last-of-type .accordion-button.collapsed {
    color: tomato;
  }

  
  & .accordion-button:not(.collapsed) {
    color: white;
    background-color: tomato;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  }
}
*/
