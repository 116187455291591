/* 공통 모듈 또는 전체 메인에 적용하고자 하는 css */
input,
textarea {
  outline: none;
  border: 1px solid lightgray;
}

input[type="number"] {
  text-align: end;
}

.template {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 250px 1fr;
  grid-template-rows: 55px calc(100vh - 55px);
  transition: 0.2s;
}

.template.mini {
  grid-template-columns: 75px 1fr;
  transition: 0.2s;
}

.main-header {
  width: 100%;
  height: 55px;
  border-bottom: 1px solid lightgray;
  display: flex;
  justify-content: space-between;
  & .header-center {
    display: flex;
    padding: 0 20px;
  }

  & .header-center .header-avatar {
    display: flex;
    gap: 5px;
    align-items: center;
    height: 100%;
  }

  & .header-center .header-avatar img {
    border-radius: 100%;
    width: 40px;
    height: 40px;
  }
  & .header-center .header-avatar .name-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
  }
  & .header-center .header-avatar .name-wrapper .name {
    font-weight: bold;
    font-size: 14px;
  }

  & .header-center .header-avatar .name-wrapper .auth {
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
    color: lightgray;
  }
  & .header-left {
    display: flex;
    gap: 20px;
  }

  & .connection-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    font-weight: bold;

    &.unconnect {
      color: tomato;
    }
    &.connected {
      color: limegreen;
    }
  }

  .header-search {
    width: 450px;
    display: flex;
    align-items: center;
  }

  .header-component {
    display: flex;
    margin-right: 5px;
    align-content: center;
    flex-wrap: wrap;
  }
}

.setting-contents {
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
}
.sc-contents {
  margin: 15px 0;
  color: #6386c6;
  font-weight: bold;
  font-size: 15px;
  display: flex;
  padding: 0 10px;
  gap: 5px;
}
.sc-contents .icon {
  color: #6386c6;
  font-size: 16px;
}

/*---------------------------------------------------------------------------------------------*/
.divider {
  display: grid;
  height: 24px;
  grid-template-columns: 1fr 3fr 3fr 1fr;
}

.divider.mini {
  display: grid;
  height: 24px;
  grid-template-columns: 1fr 2fr 1fr;
  margin-bottom: 10px;
}

.divider .divider-line {
  height: 50%;
  border-bottom: 1px solid #264b93;
}
.divider .divider-text {
  border: 1px solid #264b93;
  height: 100%;
  border-radius: 5px;
  color: #264b93;
  display: flex;
  justify-content: center;
  align-items: center;
}
.divider .divider-text.arrow {
  cursor: pointer;
  color: lightgray;
  border-color: lightgray;
}

.divider .divider-text.arrow:hover {
  color: #0099fb;
  border-color: #0099fb;
}

.body-wrapper {
  width: 100%;
  height: 100%;
  box-shadow: 1px 1px 3px lightgray;

  &.full {
    width: 100vw;
    height: 100vh;

    & .trd-editor-wrapper {
      width: 100vw;
      height: 100vh;
    }
  }
}

.contents-wrapper {
  height: 100%;
  background-color: var(--backgound-color);
  overflow: hidden;
}

.contents-body {
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-bottom: 0;
  overflow-y: auto;

  &.hidden > * {
    display: none;
  }

  & .body-wrapper {
    margin-top: 1rem;
    background-color: white;
    padding: 30px;
    height: 100%;
    max-height: calc(100% - 70px);
    overflow-y: auto;
  }
}

/* ---------------------------- TITLE --------------------------------------------------- */

.page-title-wrapper {
}

.page-title-wrapper .page-title {
  display: flex;
  justify-content: space-between;
}

h3.dd-title {
  display: flex;
  font-weight: 600 !important;
  font-size: 18px;
  align-items: center;
}

.dd-title-history {
  margin-left: 10px;
  border: 0px;
  background: none;
}

.page-title-wrapper .page-title .breadcrumb {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 0;
}

.page-title-wrapper .title-under-line {
  margin-top: 10px;
  width: 100%;
  display: flex;
}
.page-title-wrapper .title-under-line .color-block {
  width: 35px;
  border: 2px solid #032d7c;
  position: relative;
  bottom: 0;
  display: block;
}
.page-title-wrapper .title-under-line .none-color-block {
  border-bottom: 1px solid lightgray;
  width: 100%;
}

.grid-search-wrraper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
}

.form-button-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.modal-header {
  background-color: #eaf2fc !important;
}
.modal-footer {
  background-color: #eaf2fc !important;
}

.setting-app {
  margin-top: 15px;
  padding: 10px;
}

.setting-app .title-desc-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.setting-app .title-desc-wrapper select {
  width: 450px;
}

.required::after {
  content: "*";
  color: tomato;
  font-weight: bold;
}
#react-confirm-alert {
  z-index: 9999 !important;
  position: fixed !important;
}

.vc {
  display: flex;
  align-items: center;
}

.hc {
  display: flex;
  justify-content: center;
}

/************** 스크롤바 디자인 ***************************/
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background-color: lightgray;
}
::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
}
::-webkit-scrollbar-track {
  display: none;
}

/******** 라벨 디자인 *********/
.label {
  font-weight: 700;
  margin: 5px;
}

/************ 툴팁 *****************/
.question-tooltip {
  position: relative;
  cursor: pointer;
  & .tooltip-wrapper {
    visibility: hidden;
    position: absolute;
    background-color: #3c3c3c;
    color: lightgray;
    min-width: 150px;
    padding: 3px;
    border-radius: 3px;
    word-break: break-all;
    transition: 0.2s;
    &.show {
      visibility: visible;
    }

    &.right {
      left: 100%;
    }
    &.top {
      top: -100%;
    }
  }
}

/******************************   Loading  ***************************************/

.loading-wrapper {
  z-index: 99999;
  position: fixed;
  right: 0;
  bottom: 0;
  width: calc(100vw - 75px);
  height: calc(100vh - 55px);
  background-color: #ffffffa3;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  & .progress {
    background-color: transparent;
    animation: 1s linear infinite loading-circle;
    width: 30px;
    height: 30px;
    font-size: 30px;
  }
}

@keyframes loading-circle {
  0% {
    rotate: 0deg;
  }
  25% {
    rotate: 90deg;
  }
  50% {
    rotate: 180deg;
  }
  75% {
    rotate: 270deg;
  }
  100% {
    rotate: 360deg;
  }
}

/************* 경고 문구 및 반짝임 *********************/

@keyframes condemned_blink_effect {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

span.blink {
  animation: 1.5s linear infinite condemned_blink_effect;
}
.blink {
  animation: 1.5s linear infinite condemned_blink_effect;
}

.warning-text {
  color: tomato;
  margin: 0 3px;
}
