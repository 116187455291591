.tab-wrapper {
  .tab-header {
    display: flex;
    div {
      border: 1px solid #6386c6;
      padding: 5px 10px;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      font-weight: 600;
      font-size: 15px;
      border-bottom: none;
      cursor: pointer;

      &:hover {
        background-color: #7aa6f79b;
      }

      &.selected {
        background-color: #6386c6;
        color: white;
        padding: 5px 10px;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        font-weight: 600;
        font-size: 15px;
      }
    }
  }

  .tab-body {
    padding: 5px;
    width: 100%;
    height: 65vh;
    border: 1px solid #6386c6;
    overflow: auto;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;

    .setting-title {
      display: flex;
      justify-content: space-between;
      margin: 3px;
      .title {
        padding: 5px;
        font-weight: 700;
        padding-left: 15px;
        font-size: 14px;
      }
      .add-button {
      }
    }

    .grid {
      max-height: 250px;

      tr:hover {
        background-color: #afcbffb0;
      }
    }
  }
}
