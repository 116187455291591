@import "../main.scss";

.env-choice-wrapper {
  width: 100vw;
  height: 100vh;
  overflow: none;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;

  & .env-choice-popup {
    width: 30%;
    background-color: white;
    border-radius: 5px;

    & .env-choice-header {
      border-bottom: 1px solid lightgray;
      background-color: var(--light-color);
      height: 35px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      font-weight: bold;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    & .env-choice-body {
      padding: 15px;

      & .recent-env-wrapper {
        min-height: 100px;
        margin-top: 50px;
        & .recent-list-box {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          & .recent-env-content {
            cursor: pointer;
            &:hover {
              font-weight: bold;
              color: dodgerblue;
            }
          }
        }
      }
    }

    & .env-choice-footer {
      border-top: 1px solid lightgray;
      background-color: var(--light-color);
      height: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 5px;
      padding-right: 20px;
      & button {
      }
    }
  }
}
