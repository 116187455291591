.form-wrapper {
  width: 100%;
  background-color: white;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0px 0px 20px 0px;
  box-shadow: 1px 1px 3px lightgray;
  .tab-title {
    padding: 10px 20px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgb(224, 224, 224);
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .form-body {
    padding: 0px 15px;
  }
  .history {
    min-height: 40vh;
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.form-label {
  font-weight: 700;
  padding-left: 5px;
}

.daaf-codecombo {
  z-index: 999;
}
