/*---------------------------- HISTORY ------------------------------------------- */
.dd-history-manage-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  transition: 0.1s linear;
  &.detail {
    grid-template-columns: 4fr 5fr;
  }
  &.list {
    grid-template-columns: 1fr 0fr;
  }
}

.history-row {
  height: 50vh;
  overflow: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.history-grid-div {
  max-height: 30vh;
  overflow-y: auto;
  padding-right: 10px;
}

.history-compare-form-wrapper {
  min-height: 40vh;
  margin-top: 0px;
  margin-bottom: 0px;
}
